.productList {
  margin: 20px;
}

.collectionName {
  font-size: 24px;
  margin: 50px 10px 30px 10px; /* Increased margin for more space */
  text-align: center; /* Center the collection name */
}

.horizontalList {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden; /* Hide vertical scrollbar */
  gap: 16px;
  white-space: nowrap;

  /* Hide scrollbar for Internet Explorer and Edge */
  -ms-overflow-style: none;

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
}

.horizontalList::-webkit-scrollbar {
  height: 0px;
  display: none;
}

.horizontalList::-webkit-scrollbar-thumb {
  background-color: #cccccc00;
  border-radius: 4px;
}

.viewAllButton {
  width: 90px; /* Button width */
  display: block;
  align-content: center; /* Makes the button a block-level element */
  margin: 0 auto 8px auto; /* Sets margin-top to 0, centers horizontally, margin-bottom to 8px */
  background-color: #ffffff; /* Button background color */
  color: #000000; /* Text color */
  padding: 8px 16px;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #757575; /* Sets border to 1px solid black */
  transition: background-color 0.3s ease;
}

.viewAllButton:hover {
  background-color: #8a8a8a; /* Darker shade on hover */
}
