.storeCollections {
  position: relative;
  top: 40px;
  left: 0;
  right: 0;
  display: flex;
  line-height: 2rem;
  margin-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 500px;
  height: 56px;
  scroll-behavior: smooth;
  z-index: 10;
}

@media screen and (min-width: 520px) {
  /* .storeCollections {
    padding-left: calc(((100vw - var(--max-width))/2) + 20px);
  } */
}

.storeCollections::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in white */
.storeCollections::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.storeCollections::-webkit-scrollbar {
  display: none;
}
