.collectionFilter {
  margin: 4px 5px 5px 4px;
  padding: 4px 20px 4px 20px;
  border-radius: 50px;
  background-color: #f5f5f5;
  text-transform: capitalize;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  white-space: nowrap;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.selectedFilter {
  margin: 4px 5px 5px 4px;
  padding: 5px 20px;
  border-radius: 50px;
  background-color: #000;

  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  text-transform: capitalize;

  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
