.storeItems {
  display: grid;
  margin-top: 10px;
  grid-gap: 11px 22px;
  grid-template-columns: 1fr 1fr;
  /* grid-template-columns: repeat(autofill, 1fr); */
  position: relative;
}
.firstTimeCollectionProductLoader {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .storeHeading {
    margin-left: 20px;
    margin-right: 20px;
  } */
.store {
  min-height: calc(100vh - 268px);
  width: 100%;
  margin-top: 38px;
  padding: 0 22px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.store::-webkit-scrollbar {
  width: 0; /* Removes scrollbar width */
  background: transparent; /* Optional: hides the scrollbar track */
}
.productLoader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.virtualRowInner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding-right: 1rem;
}
