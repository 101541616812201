.homeDetailsContainer {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  z-index: 10;
}

.homeDetailsButton {
  width: 140px;
  height: 40px;
  border-radius: 4px;
  margin: 12.5 0 7.5px 1px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
}

.hero {
  position: relative;
  width: 100%; /* full width */
  height: 100vh; /* full height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.heroForImages {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* This will push the sides out beyond the container dimensions */
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
}

.backgroundVideo video {
  object-fit: cover; /* This will cover the area and clip excess parts */
  position: absolute;
  top: 50%;
  left: 50%;
  /* This will center the video */
  transform: translate(-50%, -50%);
}

.carouselImageContainer :global(.carousel) {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  top: -20px;
}

.carouselImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-bottom: 30px;
}

.carouselImageContainer :global(.carousel .control-dots .dot) {
  background: #444444 !important;
  box-shadow: none !important;
  transition: none !important;
  height: 7px !important;
  width: 7px !important;
  border-radius: 50% !important;
}

.carouselImageContainer :global(.carousel .control-dots .dot.selected) {
  background: #000 !important;
}
