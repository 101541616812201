.storeItem {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 30px;
  max-width: calc((100vw / 2) - 33px);
  /* margin-bottom: 50px; */
  /* height: 300px; */
  /* margin: 40px 0; */
}

.storeItemForCollectionHome {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: calc((var(--max-width) / 1.5) - 50px);
}

.productImg {
  border-radius: 10px;
  /* min-height: 104px; */
  object-fit: cover;
  width: calc((100vw / 2) - 33px);
  height: calc((100vw / 2) - 33px);
}

.productImgForCollectionHome {
  border-radius: 10px;
  /* min-height: 104px; */
  object-fit: cover;
  width: calc((var(--max-width) / 1.5) - 50px);
  height: calc((var(--max-width) / 1.5) - 50px);
}

.productImg > div > svg {
  border-radius: 10px;
}
@media screen and (min-width: 500px) {
  .productImg {
    border-radius: 10px;
    object-fit: cover;
    width: calc((520px / 2) - 33px);
    height: calc((520px / 2) - 33px);
  }
  .storeItem {
    max-width: calc((520px / 2) - 33px);
  }
}
.image_container {
  position: relative;
  border-radius: 10px;
}
.item_overlay {
  position: absolute;
  /* background-color: rgb(38, 38, 38, 0.3); */
  height: 98%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  font-weight: 900;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  color: #ff3333;
  z-index: 1;
}
.item_overlay > p {
  margin: 0;
  padding: 0;
  letter-spacing: 3px;
}
.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
  height: 100%; */

  padding: 4px 0;
}

.title {
  font-size: 16px;
  margin-bottom: 4px;
  color: #1c3142;
  opacity: 80%;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  /* min-height: 22px; */
}

.price {
  font-size: 16px;
  font-weight: 700;
  color: #1c3142;
  font-family: Roboto, sans-serif;
}

.strikeOutText {
  font-size: 14.5px;
  font-weight: 300;
  text-decoration: line-through;
  opacity: 0.6;
}

.storeNameText {
  /* word-break: break-all; */
}
